import { createContext } from 'preact'
import { defaultDocumentSettings } from '../modules/documentSettings'
import { useState, useContext } from 'preact/hooks'
import { defaultConfigs } from '../modules/filing'
import usePersistentState from '../hooks/usePersistentState'
import {
  documentTypes,
  generalDocumentType
} from '../constants/documentSettings.const'
import { jobSettingsContext } from './jobSettingsProvider'
import { GenericTaskState } from '../constants/genericTaskStates.const'
import { getErrorByStatusCode } from '../modules/errors'
import { baseContext } from './baseProvider'
import { deepCopy } from '../modules/util'
import { exists, store } from '../modules/filingClient'
import { appContext } from './appProvider'
import { localVar } from '../constants/storage.const'

export const filingContext = createContext()

const FilingProvider = ({ children }) => {
  const { cloudService } = useContext(baseContext)
  const { error, setError, trackEcommerceConsumption } = useContext(appContext)
  const [documentSettings, setDocumentSettings] = useState(
    defaultDocumentSettings
  )
  const [suggestedDocumentType, setSuggestedDocumentType] = useState()
  const [modules, setModules] = useState()
  const [configs, setConfigs] = usePersistentState(
    localVar.configurationSettings,
    defaultConfigs(cloudService.root)
  )
  const [scanAction, setScanAction] = useState()
  const [documentSaveStatus, setDocumentSaveStatus] = useState(
    GenericTaskState.NotStarted
  )
  const { scanGuid } = useContext(jobSettingsContext)

  const saveDocument = (docName, destinationFolder, serial, appId) =>
    store(scanGuid, cloudService.cloudId, docName, destinationFolder)
      .then(() =>
        trackEcommerceConsumption(serial, appId)
          .then(() => setDocumentSaveStatus(GenericTaskState.Success))
          .catch(() => setDocumentSaveStatus(GenericTaskState.Error))
      )
      .catch(e => setError(getErrorByStatusCode(e.response.status)))

  const onDocumentSaveStarted = (
    documentName,
    destinationFolder,
    appId,
    serial
  ) => {
    setDocumentSaveStatus(GenericTaskState.InProgress)
    return exists(cloudService.cloudId, destinationFolder, documentName).then(
      r =>
        r.exists
          ? setError(getErrorByStatusCode('FM_004'))
          : saveDocument(documentName, destinationFolder, serial, appId)
    )
    .catch(e => setError(getErrorByStatusCode(e.response.status)))
  }

  const resetSuggestedDocumentType = () => setSuggestedDocumentType(null)

  const setSuggestedTypeAndModules = (type, modules) => {
    const docType = documentTypes.some(
      c => c.key.toLowerCase() == type.toLowerCase()
    )
      ? type
      : generalDocumentType
    setSuggestedDocumentType(docType)
    setModules(modules)
  }

  const setDocumentType = type =>
    setDocumentSettings(ds => ({ ...ds, documentType: type }))
  const setDocumentName = name =>
    setDocumentSettings(ds => ({ ...ds, documentName: name }))
  
  const setDestinationFolder = df =>
    setDocumentSettings(ds => ({ ...ds, destinationFolder: df }))

  const setDestinationFolderIdStack = id =>
    setDocumentSettings(ds => ({ ...ds, destinationFolderIdStack: id }))

  const resetDocumentSettings = () => {
    setDocumentSettings(defaultDocumentSettings)
  }

  const getConfigByKey = (_configs, _key) => {
    return _configs?.find(k => k.key.toLowerCase() == _key.toLowerCase())
  }

  const getConfigItem = (name, key) => {
    const configsCopy = deepCopy(configs)
    const matchingConfig = getConfigByKey(configsCopy, key)
    return matchingConfig && matchingConfig.enabled ? matchingConfig[name] : ''
  }

  const setConfigItem = (name, key, value) => {
    setConfigs(prevData=>{
      const configsToUpdate = deepCopy(prevData)
      const matchingConfig = getConfigByKey(configsToUpdate, key)
      matchingConfig[name] = deepCopy(value)
      return (configsToUpdate)
    })
  }

  const toggleConfigEnabled = (key, newValue) => {
    setConfigs(prevData=>{
      const configsToUpdate = deepCopy(prevData)
      const matchingConfig = getConfigByKey(configsToUpdate, key)
      matchingConfig.enabled = newValue
      return configsToUpdate;
    })
  }

  const getConfigEnabled = key => {
    const matchingConfig = getConfigByKey(configs, key)
    return matchingConfig.enabled
  }

  const resetConfigs = () => setConfigs(defaultConfigs(cloudService.root))

  //This function is used to reset default location for all config types
  //if the current cloudProvider is different than the one in localStorage
  const resetDefaultLocations = () => {
    setConfigs(prevState =>{
      const configsToUpdate = deepCopy(prevState)
      const defaultConf = defaultConfigs(cloudService.root)
      configsToUpdate.map(c => {
        return {
          ...c,
          defaultLocation: defaultConf.defaultLocation,
          defaultLocationIdStack: defaultConf.defaultLocationIdStack,
          draftDefaultLocation: defaultConf.draftDefaultLocation,
          draftDefaultLocationIdStack: defaultConf.draftDefaultLocationIdStack
        }
      })
      return configsToUpdate;
    })
  }

  const contextElements = {
    configs,
    setConfigs,
    resetConfigs,
    documentSettings,
    setDocumentType,
    setDocumentName,
    setDestinationFolder,
    setDestinationFolderIdStack,
    setSuggestedTypeAndModules,
    resetSuggestedDocumentType,
    resetDefaultLocations,
    suggestedDocumentType,
    resetDocumentSettings,
    getConfigItem,
    setConfigItem,
    toggleConfigEnabled,
    getConfigEnabled,
    scanAction,
    setScanAction,
    modules,
    documentSaveStatus,
    setDocumentSaveStatus,
    error,
    setError,
    onDocumentSaveStarted
  }

  return (
    <filingContext.Provider value={contextElements}>
      {children}
    </filingContext.Provider>
  )
}

export default FilingProvider
