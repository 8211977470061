import { cloudServices } from "../constants/cloudServices.const";

export const getCloudService = key => {
    const cloud = Object.values(cloudServices).find(service => service.keys.includes(key? key.toLowerCase() : null));
    const cloudId = Object.keys(cloudServices)[Object.values(cloudServices).indexOf(cloud)];
    return { ...cloud, 
        cloudId, 
        isOneDrive: cloudId == "onedrive",
        isDocushare: cloudId == "docushare" || cloudId == "docushare_eu",
        isSharepoint: cloudId == "sharepoint",        
        isGoogleDrive: cloudId == "googledrive", 
        root: cloud?.root || "/" 
    }
}

