/* --------------------------------------------------------------------------------------
   xwInput.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { useRef, useEffect, useContext, useState } from "preact/hooks"
import { baseContext } from "../../providers/baseProvider"
import { MFDApp } from "../../constants/MFDApp.const"
import { closeBracketFunc } from "../../modules/util"

const XwInput = props => {
    const elementRef = useRef(null)
    const { buttonClick } = useContext(baseContext)
    const inputWidth = `${props.width  }px`
    const propsCssClass  = props.className? props.className : ""
    const glyphCssClass = props.glyph? "input-glyph-padding" : ""
    const inputCssClass = `${propsCssClass} ${glyphCssClass}`
    const [initialized, setInitialized] = useState(false)
    const [hasInputFocus, setHasInputFocus] = useState(false);
    
    const getOptions = props => ({
        ...props,
        theme: props.theme || MFDApp.theme,
        layout: props.layout || navigator.language.substring(0, 2).toLowerCase(),
        change: () => props.modelChange($(elementRef.current).getkeyboard().$preview.val()),
        visible: () => closeBracketFunc()
    })


    useEffect(() => {
        $(elementRef.current).xrxkeyboard(getOptions(props))
        return () => $(elementRef.current).xrxkeyboard("destroy")
    }, [])

    useEffect(() => {
        if (initialized) {
            $(elementRef.current).getkeyboard().reveal()
        } else {
            setInitialized(true)
        }
    }, [props.showOn])
    
    useEffect(() => $(elementRef.current).getkeyboard().accept(), [buttonClick])
    
    return <>
        {props.glyph && <span class={`input-glyph xrx-glyphicon xglyph-${props.glyph} ${hasInputFocus ? "focus" : ""}`} />}
        <input ref={elementRef}
            type="text"
            style={{ width: inputWidth }}
            className={inputCssClass}
            value={props.model}
            onFocus={()=>setHasInputFocus(true)}
            onBlur={()=>setHasInputFocus(false)}
        />
    </>
}

export default XwInput