/* --------------------------------------------------------------------------------------
   index.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Localizer, Text } from 'preact-i18n'
import { exitApp } from '../../modules/util'
import { route } from 'preact-router'
import { useState } from 'preact/hooks'
import { memo } from "preact/compat";
import {
  XwActionBar,
  XwButton,
} from '../../xerox/widgets'
import { getPrivacyStatement } from "../../modules/privacyStatementClient"
import PrivacyStatementPopup from './privacyStatementPopup'
import { getErrorByStatusCode } from '../../modules/errors'
import ErrorAlert from '../errorAlert'
import { getCloudService } from '../../modules/cloud';
import { getAppGallerySettings } from '../../modules/appGallery';

const PrivacyStatement = () => {
  const [appGallerySettings] = useState(getAppGallerySettings())
  const { cloud } = appGallerySettings || {}
  const [ cloudService ] = useState(getCloudService(cloud))   

  const actionBarTitle = <Text id='PRIVACY.ACCEPT_PRIVACY_TITLE' />
  const acceptButton = <Text id='PRIVACY.ACCEPT_BUTTON' />
  const cancelButton = <Text id='PRIVACY.CANCEL_BUTTON' />
  const consentPrompt = <Text id='PRIVACY.CONSENT_PROMPT' fields={{product:cloudService.name, productIssuer:cloudService.issuer}} />
  const additionalPrompt = <Text id='PRIVACY.CONSENT_PROMPT_ADDITIONAL' />
  const viewStatementLabel = <Text id="PRIVACY.VIEW_STATEMENT" />
  const cancel = () => exitApp();
  const accept = () => route('/login');
  const [error, setError] = useState();
  const [statement, setStatement] = useState();
  const [loading, setLoading] = useState(false)
  const [displayPopup, setDisplayPopup] = useState(false)



  const onViewStatementClicked = () =>{
    setLoading(true)
    setDisplayPopup(true)
    getPrivacyStatement(window.navigator.language)
    .then((data) => {
        setStatement(data)
        setLoading(false)
      })
    .catch((err)=>{
      setLoading(false)
      setDisplayPopup(false)
      setError(getErrorByStatusCode(err.response.status))
    })
  }

  const onPopupClosed = () =>{
    setLoading(false);
    setDisplayPopup(false);
  }
   return ( <div>
      <Localizer>
        <XwActionBar
          label={actionBarTitle}
          id='privacyStatementActionBar'
          themePrimary={false}
        />
      </Localizer>
        <div class="xmx-2 text-align-center privacy-content-wrapper">
            <div class="privacy-content xmx-2">
              <p class=' purple'>{consentPrompt}</p>
            <p class='xmt-2 purple'>{additionalPrompt}</p>
            <div class="privacy-content-buttons">
          <Localizer>
            <XwButton
              id='closeButton'
              label={cancelButton}
              onEnabledClick={cancel}
              widthScale="4"
            />
          </Localizer>
          <Localizer>
            <XwButton
              themePrimary={true}
              id='acceptPrivacyButton'
              label={acceptButton}
              onEnabledClick={accept}
              widthScale="4"
            />
          </Localizer>
          </div>
            </div>  
        </div>
        <div class='footer-btn'>
            <Localizer>
              <XwButton
                label={viewStatementLabel}
                onEnabledClick={onViewStatementClicked}
                className="view-privacy-statement-btn"
              />
            </Localizer>
          </div>
        {displayPopup && 
        <PrivacyStatementPopup loading={loading} display={displayPopup} statement={statement} onClose={onPopupClosed} />
          }
        {!!error && 
        <ErrorAlert error={error} onClose={exitApp} />
        }
    </div>
   );
}
export default memo(PrivacyStatement)
