/* --------------------------------------------------------------------------------------
   documentSettings.const.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

// Invoices
// Document Number (invoice number)
// Date (invoice date)
// Company Name (sender)
// Bills
// Date (billing date)
// Company Name (sender)
// Delivery Notes
// Document Number (delivery note number)
// Date (delivery note date)
// Company Name (sender)
// Agreements
// Date (date of agreement)
// Parties (the people/companies that the agreement relates to)
// Agreement Type
// Bank Statements
// Bank Name
// Date (statement date)
// Bank Account Number
// Correspondence
// Date (date of letter)
// Name
// Company Name
// Subject
// Misc
// Date
// Title
// Name
// Company Name
export const variableBlock = 'variable-block'
export const textBlock = 'text-block'

//Bank
const bankStatementDate = {key:'Bank Statement_Date', value: 'CONFIG_SETTINGS.BANK_STATEMENT.DATE'}
const bankStatementAccount = { key: 'Bank Statement_Account Number', value: 'CONFIG_SETTINGS.BANK_STATEMENT.ACCOUNT_NUMBER' }
const bankStatementName = { key: 'Bank Statement_Bank Name', value: 'CONFIG_SETTINGS.BANK_STATEMENT.BANK_NAME' }

//Agreement
const agreementParties = { key: 'Agreement Parties', value: 'CONFIG_SETTINGS.CONTRACT.PARTIES' }
const agreementType = { key: 'Agreement Type', value: 'CONFIG_SETTINGS.CONTRACT.TYPE' }
const agreementDate = { key: 'Agreement Date', value: 'CONFIG_SETTINGS.CONTRACT.DATE' }

//Bills
const billDate = {key:'Bill_Date', value: 'CONFIG_SETTINGS.BILL.DATE'}
const billCompanyName = {key:'Bill_Company Name', value: 'CONFIG_SETTINGS.BILL.COMPANY_NAME'}

//Correspondence
const correspondenceDate = {key: 'Correspondence_Date', value: 'CONFIG_SETTINGS.CORRESPONDENCE.DATE'}
const correspondenceSubject = {key: 'Correspondence_Subject', value: 'CONFIG_SETTINGS.CORRESPONDENCE.SUBJECT'}
const correspondenceName = {key: 'Correspondence_Name', value: 'CONFIG_SETTINGS.CORRESPONDENCE.NAME'}

//delivery notes
const deliveryNoteDate = {key: 'Delivery Note_Date', value: 'CONFIG_SETTINGS.DELIVERY_NOTE.DATE'}
const deliveryNoteDocumentNumber = {key: 'Delivery Note_Document Number', value: 'CONFIG_SETTINGS.DELIVERY_NOTE.DOCUMENT_NUMBER'}
const deliveryNoteCompanyName = {key: 'Delivery Note_Company Name', value: 'CONFIG_SETTINGS.DELIVERY_NOTE.COMPANY_NAME'}

//invoices
const invoiceDate = {key:'Invoice_Date', value:'CONFIG_SETTINGS.INVOICE.DATE'}
const invoiceDocumentNumber = {key:'Invoice_Document Number', value:'CONFIG_SETTINGS.INVOICE.DOCUMENT_NUMBER'}
const invoiceCompanyName = {key:'Invoice_Company Name', value:'CONFIG_SETTINGS.INVOICE.COMPANY_NAME'}


//misc
const miscDate = {key:'Misc_Date', value: 'CONFIG_SETTINGS.GENERAL.DATE'}
const miscName = {key:'Misc_Name', value: 'CONFIG_SETTINGS.GENERAL.NAME'}
const miscCompanyName = {key:'Misc_Company Name', value: 'CONFIG_SETTINGS.GENERAL.COMPANY_NAME'}


export  const generalDocumentType = "misc"

const invoiceVariables = [invoiceDate, invoiceDocumentNumber, invoiceCompanyName]

const billVariables = [billDate, billCompanyName]

const deliveryNoteVariables = [deliveryNoteDate, deliveryNoteDocumentNumber, deliveryNoteCompanyName]

const agreementVariables = [agreementDate, agreementParties, agreementType]

const bankStatementVariables = [bankStatementDate, bankStatementName, bankStatementAccount]

const correspondenceVariables = [correspondenceDate, correspondenceSubject, correspondenceName]

const miscVariables = [miscDate, miscName, miscCompanyName]

export const documentTypes = [
  {
    key: 'invoices',
    text: 'DOCUMENT_TYPE.INVOICE',
    settingsTitle: 'CONFIG_SETTINGS.INVOICE.TITLE',
    singular: 'CONFIG_SETTINGS.INVOICE.SINGULAR',
    variables: invoiceVariables,
    defaultDocumentName: [
        {
            key: "filename-input-text-6212f876-a7db-4bd4-96c6-b5318824b433",
            value: "",
            type: textBlock
        },
      {
        ...invoiceCompanyName,
        id: '6ec3573c-5613-4c15-9146-228244dc7451',
        type: variableBlock
    },
    {
      key: "filename-input-text-54b78f6d-9935-4fc0-9564-4433c11da8e0",
      value: "-",
      type: textBlock
  },
      {
          ...invoiceDocumentNumber,
          id:'c8f9a8db-e8b8-45cd-9003-043f93764252',
          type: variableBlock
      },
      {
          key: "filename-input-text-5732fe9b-1bcc-4dcc-a98e-7cbf1c154540",
          value: "-",
          type: textBlock
      },
      {
          ...invoiceDate,
          id: '1342e0dd-1a83-40ce-9393-f3861975f9f2',
          type: variableBlock
      },
      {
          key: "filename-input-text-5090c536-d046-4319-9623-aab79c934c2b",
          value: "",
          type: textBlock
      }
  ]
  },
  {
    key: 'bills',
    text: 'DOCUMENT_TYPE.BILL',
    settingsTitle: 'CONFIG_SETTINGS.BILL.TITLE',
    singular: 'CONFIG_SETTINGS.BILL.SINGULAR',
    variables: billVariables,
    defaultDocumentName: [
        {
            key: "filename-input-text-8a52644d-9d7d-47fb-b204-a09eb25bc094",
            value: "",
            type: textBlock
        },
      {
        ...billCompanyName,
        id:'9d1af48e-7a2c-48f5-97fb-048632046555',
          type: variableBlock
      },
      {
          key: "filename-input-text-abfff854-be1c-474d-b406-b7397b533f5b",
          value: "-",
          type: textBlock
      },
      {
          ...billDate,
          id:'8ef38479-6404-4428-a34a-2c41e64565e8',
          type: variableBlock
      },
      {
          key: "filename-input-text-bdc97e88-d4e7-4827-b4db-3031fd86b57d",
          value: "",
          type: textBlock
      }
  ]
  },
  {
    key: 'delivery notes',
    text: 'DOCUMENT_TYPE.DELIVERY_NOTE',
    settingsTitle: 'CONFIG_SETTINGS.DELIVERY_NOTE.TITLE',
    singular: 'CONFIG_SETTINGS.DELIVERY_NOTE.SINGULAR',
    variables: deliveryNoteVariables,
    defaultDocumentName: [
        {
            key: "filename-input-text-b1c022f8-f555-4975-8818-896a77617f63",
            value: "",
            type: textBlock
        },
      {
         ...deliveryNoteCompanyName,
         id:'049ba08e-e9f5-421c-a920-df2c72dcf99e',
          type: variableBlock
      },
      {
          key: "filename-input-text-6a9114b5-a0f0-4273-883a-9031980f462d",
          value: "-",
          type: textBlock
      },
      {
          ...deliveryNoteDocumentNumber,
          id:'db1b439e-9090-4fb7-9498-183601ed681f',
          type: variableBlock
      },
      {
          key: "filename-input-text-a58884ec-20cb-4702-a0ed-e7d760016fe1",
          value: "-",
          type: textBlock
      },
      {
         ...deliveryNoteDate,
         id:'c590ba97-1d08-49fa-a606-eaaa0f6b48e1',
          type: variableBlock
      },
      {
          key: "filename-input-text-1b26ff38-83b5-44f3-830e-d42b0f8063dd",
          value: "",
          type: textBlock
      }
  ]
  },
  {
    key: 'agreements',
    text: 'DOCUMENT_TYPE.CONTRACT',
    settingsTitle: 'CONFIG_SETTINGS.CONTRACT.TITLE',
    singular: 'CONFIG_SETTINGS.CONTRACT.SINGULAR',
    variables: agreementVariables,
    defaultDocumentName: [
        {
            key: "filename-input-text-fccaac8c-4f19-4698-8f67-2df1467081f4",
            value: "",
            type: textBlock
        },
      {
       ...agreementParties,
       id:'5f2a6b26-6227-48bb-9ba3-7661af76a1cf',
        type: variableBlock
    },
      {
          key: "filename-input-text-87fbca34-52e0-46a8-9dba-c1b0931a7153",
          value: "-",
          type: textBlock
      },
      {
          ...agreementType,
          id:'d498bb27-2584-4817-889c-73c81dd5396e',
          type: variableBlock
      },
      {
          key: "filename-input-text-fd56f880-f785-4a70-83cb-8986b1a1949a",
          value: "-",
          type: textBlock
      },
      
      {
          ...agreementDate,
          id:'0c2b19de-23e1-4286-87d0-651f73320020',
          type: variableBlock
      },
      {
          key: "filename-input-text-41098e04-2043-43b3-90b1-278c26fb0c88",
          value: "",
          type: textBlock
      }
  ]
  },
  {
    key: 'bank statements',
    text: 'DOCUMENT_TYPE.BANK_STATEMENT',
    settingsTitle: 'CONFIG_SETTINGS.BANK_STATEMENT.TITLE',
    singular: 'CONFIG_SETTINGS.BANK_STATEMENT.SINGULAR',
    variables: bankStatementVariables,
    defaultDocumentName: [
        {
            key: "filename-input-text-154bd393-9529-46f1-ae3d-e5dd0094ee95",
            value: "",
            type: textBlock
        },
      {
         ...bankStatementName,
         id:'3c9a4d29-2fc0-4c8b-84f9-4b965630fa95',
          type: variableBlock
      },
      {
          key: "filename-input-text-b6ab45b6-1982-43a8-af9b-aaeb82c50e0f",
          value: "-",
          type: textBlock
      },
      {
          ...bankStatementAccount,
          id:'4b31712d-db89-48dc-a2d1-5d5efb9e1d3c',
          type: variableBlock
      },
      {
          key: "filename-input-text-90099d60-747f-49b0-bf81-65da114bd376",
          value: "-",
          type: textBlock
      },
      {
          ...bankStatementDate,
          id:'e00bb6ad-d47b-4a2b-b96e-95f5dc4237a6',
          type: variableBlock
      },
      {
          key: "filename-input-text-e787d6c8-2572-4cbc-83a1-5f4d461860a7",
          value: "",
          type: textBlock
      }
  ]
  },
  {
    key: 'correspondence',
    text: 'DOCUMENT_TYPE.CORRESPONDENCE',
    settingsTitle: 'CONFIG_SETTINGS.CORRESPONDENCE.TITLE',
    singular: 'CONFIG_SETTINGS.CORRESPONDENCE.SINGULAR',
    variables: correspondenceVariables,
    defaultDocumentName: [
        {
            key: "filename-input-text-f5d49347-0958-4df3-9aee-24ce7cb92ae6",
            value: "",
            type: textBlock
        },
      {
          ...correspondenceName,
          id:'e6a8cf99-68a8-4130-9092-82d8d6b206e2',
          type: variableBlock
      },
      {
          key: "filename-input-text-3d59b339-1573-43ad-960b-161b02df5785",
          value: "-",
          type: textBlock
      },
      {
          ...correspondenceSubject,
          id:'1697ffbe-6b33-4e26-a3ac-854dcb35840d',
          type: variableBlock
      },
      {
          key: "filename-input-text-a98e4d90-c081-406a-b980-aa931f4036f1",
          value: "-",
          type: textBlock
      },
      {
          ...correspondenceDate,
          id:'d90d7033-d942-4fa1-b8de-099eb6ad806c',
          type: variableBlock
      },
      {
          key: "filename-input-text-a10186a1-9ef7-44f9-a9da-7702ff75f948",
          value: "",
          type: textBlock
      }
  ]
  },
  {
    key: generalDocumentType,
    text: 'DOCUMENT_TYPE.GENERAL',
    settingsTitle: 'CONFIG_SETTINGS.GENERAL.TITLE',
    singular: 'CONFIG_SETTINGS.GENERAL.SINGULAR',
    variables: miscVariables,
    defaultDocumentName: [
        {
            key: "filename-input-text-61f6e75f-9e99-44d8-857c-a254ff7794bf",
            value: "",
            type: textBlock
        },
      {
        ...miscCompanyName,
        id:'ad59d0c3-c0c3-4ea1-88d7-f3adfe0b5f69',
          type: variableBlock
      },
      {
          key: "filename-input-text-2e50e031-8427-4780-aaaf-abd74cc20bd2",
          value: "-",
          type: textBlock
      },
      {
          ...miscName,
          id:'524b92a8-87ac-423b-add0-b5fcd3cfb3dd',
          type: variableBlock
      },
      {
          key: "filename-input-text-8ec94bcc-c0eb-4a42-ae8d-6320c701b24f",
          value: "-",
          type: textBlock
      },
      {
        ...miscDate,
        id:'181aee6e-2332-4081-8b55-1dbb05c6b6c5',
        type: variableBlock
    },
  ]
  }
]




