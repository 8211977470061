import { createContext } from 'preact'
import { useState } from 'preact/hooks'
import BrowseProvider from './browseProvider'
import JobSettingsProvider from './jobSettingsProvider'
import FilingProvider from './filingProvider'
import ValidationProvider from './validationProvider'
import {
  trackCreditConsumption,
  getSummary,
  getRemainingCredits
} from '../modules/ecommerceClient'
import { getErrorByStatusCode, ecommerceSummaryError } from '../modules/errors'

export const appContext = createContext()

const AppProvider = ({ children }) => {
  const [ecommerceBalance, setEcommerceBalance] = useState()
  const [ecommerceSummary, setEcommerceSummary] = useState()
  const [isEnterpriseEcommerce, setIsEnterpriseEcommerce] = useState()
  const [error, setError] = useState()

  //TODO: move to an ecommerce module
  const getEcommerceSummary = (serial, appId) =>
    getSummary(serial, appId).then(r => {
      if (!r.isEntitled) {
        return ecommerceSummaryError(r)
      }
      setEcommerceSummary(r)
    })

  const trackEcommerceConsumption = (serial, appId) => {
    if (
      ecommerceSummary &&
      ecommerceSummary.isEntitled &&
      ecommerceSummary.usage
    ) {
      return trackCreditConsumption(
        serial,
        appId,
        1,
        ecommerceSummary.usage[0].unit
      ).then(() =>
        getEcommerceSummary(serial, appId).catch(e =>
          setError(getErrorByStatusCode(e.response.status))
        )
      )
    }
    return Promise.reject({
      response: {
        status: 'FM_002'
      }
    })
  }

  const getEcommerceRemainingCredits = (serial, appId) =>
    getRemainingCredits(serial, appId, ecommerceSummary.usage[0].unit).then(
      setEcommerceBalance
    )
  const contextElements = {
    ecommerceBalance,
    ecommerceSummary,
    isEnterpriseEcommerce,
    setIsEnterpriseEcommerce,
    getEcommerceSummary,
    getEcommerceRemainingCredits,
    trackEcommerceConsumption,
    error, 
    setError
  }
  return (
    <appContext.Provider value={contextElements}>
      <BrowseProvider>
        <JobSettingsProvider>
          <FilingProvider>
            <ValidationProvider>{children}</ValidationProvider>
          </FilingProvider>
        </JobSettingsProvider>
      </BrowseProvider>
    </appContext.Provider>
  )
}

export default AppProvider
