import { documentTypes } from "../constants/documentSettings.const";

export const defaultConfigs = (locationRoot) => documentTypes.map(dt => ({
    key: dt.key,
    text: dt.text,
    settingsTitle: dt.settingsTitle,
    defaultDocumentName: dt.defaultDocumentName,
    defaultLocation: locationRoot,
    defaultLocationIdStack: ["/"],
    draftDefaultLocation: locationRoot,
    draftDefaultLocationIdStack: ["/"],
    enabled: true
}))

//SUGGESTED: Move to a constants file if this grows
export const configItems = {
    defaultDocumentName: 'defaultDocumentName',
    defaultLocation: 'defaultLocation',
    draftDefaultLocation: 'draftDefaultLocation',
    defaultLocationIdStack: 'defaultLocationIdStack',
    draftDefaultLocationIdStack: 'draftDefaultLocationIdStack'
};

const addExtension = str => str.toLowerCase().substr(-4) !== '.pdf' ? `${str}.pdf` : str;

export const getDocumentFilename = documentName => addExtension(documentName)