import { useState, useEffect } from 'preact/hooks';
import { getLocalVar, setLocalVar } from "../modules/browserStorage"

const usePersistentState =  (storageKey, initialState) => {

  const [state, setInternalState] = useState(initialState);
  useEffect(() => {
    
    const storageInBrowser = JSON.parse(getLocalVar(storageKey));
    if (storageInBrowser) {
      setInternalState(storageInBrowser);
    }
  }, [storageKey]);

  useEffect(()=>{
    setLocalVar(storageKey, JSON.stringify(state))
  },[state, storageKey])

  const setState = (stateUpdater) => {
      setInternalState(stateUpdater);
  };

  return [state, setState];
};

export default usePersistentState;