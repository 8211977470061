/* eslint-disable no-extra-boolean-cast */
/* --------------------------------------------------------------------------------------
   util.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { localVar } from "../constants/storage.const";
import { clearLocalVar, getLocalVar } from "./browserStorage";

export const deviceUrl = location.port == "8080" ? process.env.PREACT_APP_DEBUG_DEVICE_IP : null

export const generateUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const parseJwt = (token) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
    return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
  }).join(''));

  return JSON.parse(jsonPayload);
}
export const getSearchParams = paramsObj => {
  const result = new URLSearchParams()
  Object.keys(paramsObj).forEach(k => {
    result.append(k, paramsObj[k])
  });
  return result
}

export const getUrlSearchParamsAsObj = () => {
  const queryStringParameters = new URLSearchParams(window.location.search);
  const entries = queryStringParameters.entries();
  let obj = {};
  for (let i = entries.next(); !i.done; i = entries.next()) {
    const [key, value] = i.value;
    obj[key] = decodeURIComponent(value);
  }
  return obj;
}

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj))

export const exitApp = () => {
  try {
    ExitCUIMode()
  } catch (error) {
    console.log("ExitCUIMode() function not found")
    location.href = "about:blank"
  }
}

export const is5inches = screen.width < 801

const companyName = { key: 'Company Name', value: 'Company Name' }
const date = { key: 'Date', value: 'Date' }
const time = { key: 'Time', value: 'Time' }
const invoiceNumber = { key: 'Invoice Number', value: 'Invoice Number' }
const deliveryNoteNumber = {
  key: 'Delivery Note Number',
  value: 'Delivery Note Number'
}
const title = { key: 'Title', value: 'Title' }
const bankAccount = { key: 'Bank Account Number', value: 'Bank Account Number' }
const bankName = { key: 'Bank Name', value: 'Bank Name' }
const parties = { key: 'Parties', value: 'Parties' }
const documentNumber = { key: 'Document Number', value: 'Document Number' }
const name = { key: 'Name', value: 'Name' }
const subject = { key: 'Subject', value: 'Subject' }

const oldKeys = [companyName, date, time, invoiceNumber, deliveryNoteNumber, title, bankAccount, bankName, parties, documentNumber, name, subject]

export function clearOldLocalStorageKeys() {
  let localConfigStr = getLocalVar(localVar.configurationSettings)
  if (!!localConfigStr) {
    let localConfig = JSON.parse(localConfigStr)
    let hasOldKeys = localConfig.some(c => c.defaultDocumentName.some(d => oldKeys.some(k => k.key.toLowerCase() === d.key.toLowerCase())))
    if (hasOldKeys) {
      clearLocalVar(localVar.configurationSettings)
    }
  }
}

export function closeBracketFunc() {
  const closeBracketArr = document.getElementsByClassName('ui-keyboard-58033')
  for (let i = 0; i < closeBracketArr.length; i++) {
    const closeBracket = closeBracketArr[i];
    if (closeBracket) {
      closeBracket.attributes.getNamedItem('data-value').textContent = '}'
      closeBracket.attributes.getNamedItem('data-action').textContent = '}'
      closeBracket.attributes.getNamedItem('data-name').textContent = '}'
      closeBracket.attributes.getNamedItem(
        'data-html'
      ).textContent = `<span class="ui-keyboard-text">}</span>`
      if (closeBracket?.firstChild) {
        closeBracket.firstChild.textContent = '}'
      }
    }
  }
}

export const hasAnyValue = (term, values) => values.some(value => term.includes(value))