import { route } from "preact-router"
import { sessionVar } from "../constants/storage.const"
import { getSessionVar } from "./browserStorage"
import { MFDApp } from "../constants/MFDApp.const"
import { hasAnyValue } from "./util"


const isUrlSecured = url => hasAnyValue(url, MFDApp.securedRoutes)
const onGuestRouteChange = () => getSessionVar(sessionVar.appStarted) || route('/', true)
const onSecureRouteChange = () => getSessionVar(sessionVar.xsaUserToken) || route('/', true)

export const onRouteChange = route => isUrlSecured(route.url)? onSecureRouteChange() : onGuestRouteChange()

