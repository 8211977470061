/* --------------------------------------------------------------------------------------
   app.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
   
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { h } from 'preact'
import { Router } from 'preact-router'

// Code-splitting is automated for `routes` directory
import Home from '../routes/home'
import OauthRedirect from '../routes/oauth_redirect'
import BaseProvider from '../providers/baseProvider'
import Xeroxsso from '../routes/xeroxsso'
import Scan from '../routes/scan'
import Configuration from '../routes/configuration'
import DocumentType from '../routes/documentType'
import DestinationFolder from '../routes/destinationFolder'
import DocumentName from '../routes/documentName'
import ConfigSettings from '../routes/configSettings'
import Browse from '../routes/browse'
import Login from '../routes/login'
import PrivacyStatement from './privacyStatement'
import AppProvider from '../providers/appProvider'
import { useEffect } from 'preact/hooks'
import {memo} from "preact/compat";
import {
  clearOldLocalStorageKeys,
  deviceUrl
} from '../modules/util'
import { setSessionVar } from '../modules/browserStorage'
import { sessionVar } from '../constants/storage.const'
import { onRouteChange } from '../modules/routing'
import ValidationBanner from './validationBanner'

const App = () => {
  useEffect(() => {
    setSessionVar(sessionVar.deviceUrl, deviceUrl)
    clearOldLocalStorageKeys()
  }, [])

  return (
    <div id='app'>
      <BaseProvider>
        <Router onChange={onRouteChange}>
          <Home path='/' />
          <PrivacyStatement path='/privacy' />
          <Login path='/login' />
          <Xeroxsso path='/xeroxsso' />
          <OauthRedirect path='/oauth_redirect' />
        </Router>
        <AppProvider>
          <Router>
            <Scan path='/scan/:action?' />
            <Browse path='/browse/:rc+' />
            <DocumentType path='/documenttype' />
            <DestinationFolder path='/destination' />
            <DocumentName path='/documentname' />
            <Configuration path='/configuration' />
            <ConfigSettings path='/configuration/:setting' />
          </Router>
          <ValidationBanner />
        </AppProvider>
      </BaseProvider>
    </div>
  )
}

export default memo(App)
