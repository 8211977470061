/* --------------------------------------------------------------------------------------
   index.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Localizer, Text } from "preact-i18n";
import { XwButton } from "../../xerox/widgets";
import BaseModal from "../baseModal";
import ErrorAlertBody from "./errorAlertBody";

const ErrorAlert = ({ error, onClose }) => {
    const offsets = {
        0: "40%",
        1: "35%",
        2: "31%",
        3: "25%",
        4: "20%",
        5: "18%"
    }
    const closeButtonText = <Text id="COMMON.CLOSE" />
    const lines = error?.textKeys.length
    const offset = error? offsets[lines] : "30%"
    return <BaseModal alert={true} display={error} offsetTop={offset}>
        {error && <ErrorAlertBody error={error} />}
        <div class="xmt-3">
            <Localizer>
                <XwButton theme="notificationblack" label={closeButtonText} glyph="close" onEnabledClick={onClose} />
            </Localizer>
        </div>
    </BaseModal>
}

export default ErrorAlert;
