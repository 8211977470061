import { localVar } from "../constants/storage.const"
import { getLocalVar, setLocalVar } from "./browserStorage"
import { getUrlSearchParamsAsObj } from "./util"

const urlSearchParamsObj = getUrlSearchParamsAsObj()
const calledByShell = !!urlSearchParamsObj.serial
const storeAppGallerySettings = () => setLocalVar(localVar.appGallerySettings, JSON.stringify(urlSearchParamsObj))
const getStoredAppGallerySettings = () => JSON.parse(getLocalVar(localVar.appGallerySettings))

export const getAppGallerySettings = () => {
    calledByShell && storeAppGallerySettings()
    return getStoredAppGallerySettings()
} 
