/* --------------------------------------------------------------------------------------
   index.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Localizer, Text } from "preact-i18n"
import { XwActivity, XwButton } from "../../xerox/widgets"
import BaseModal from "../baseModal"

const Spinner = props => {
    const closeButtonText = <Text id="COMMON.CLOSE" />
    const hasText = !!props.spinnerText
    const offset = props.completed? "32%" : "30%"
    // NOTE: Spinner completed status is hidden. It's not working properly on device.
    return <BaseModal alert={true} display={hasText} hidden={hasText} offsetTop={offset}>
        {
            hasText && !props.completed ? 
                <div class="center-activity xpt-3 xpb-3">
                    <XwActivity widgetSize="xrx-large" completed={props.completed} /> 
                </div>
            : <></>
        }
        <p class="text-white single-line-ellipsis xmx-2">{props.spinnerText}</p>
        {props.completed? <div class="xmt-3">
            <Localizer>
                <XwButton theme="notificationblack" label={closeButtonText} glyph="close" onEnabledClick={props.onClose} />
            </Localizer>
        </div> : null}
    </BaseModal>
}

export default Spinner