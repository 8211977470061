/* --------------------------------------------------------------------------------------
   xwScrollable.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { useRef, useEffect } from "preact/hooks"
import { MFDApp } from "../../constants/MFDApp.const"

const XwScrollable = props => {
    const elementRef = useRef(null)
    const elementId = `scr-${new Date().getTime()}`
    useEffect(() => {
        props.height && $(elementRef.current).xrxscrollable(getOptions(props))
        return () => props.height && $(elementRef.current).xrxscrollable("destroy")
    }, [props.contentSizeRef, props.height])
    return <div ref={elementRef} id={elementId}>
        {props.children}
    </div>
}

const getOptions = props => ({
    ...props,
    theme: props.theme || MFDApp.theme,
    children: undefined
})

export default XwScrollable