import { axiosInstance } from "./apiClient"


export const exists = (storageProvider, path, fileName) => axiosInstance.get(
    `/storage/${storageProvider}/file/exists`, 
    { headers: { path: path, filename: fileName  }   
}).then(r=>r.data)

export const store = (scanGuid, storageProvider, fileName, directory) => axiosInstance.post(`/job/${scanGuid}/store`, {
        storageProvider,
        fileName,
        directory
    })
    .then(r=>r.data)
    .catch(e=> Promise.reject(e))
 