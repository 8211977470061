export const sessionVar = {
    appStarted: "appStarted",
    deviceUrl: "deviceUrl",
    communityString: "communityString",
    sharepointSite: "sharepointSite",
    apiKey: "apiKey",
    xsaToken: "xsaToken",
    xsaUserToken: "xsaUserToken",
    refreshToken: "refreshToken"
}

export const localVar = {
    appGallerySettings: "appGallerySettings",
    userScanSettings: "userScanSettings",
    configurationSettings: "configurationSettings",
    loginstate: "loginstate",
    configSettingsCloud: "configSettingsCloud"
}