/* --------------------------------------------------------------------------------------
   browseProvider.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { createContext } from "preact";
import { useContext, useRef, useState } from "preact/hooks";
import { browseTo, fixPath } from "../modules/browse";
import { addFolder, getFolder } from "../modules/browseClient";
import { baseContext } from "./baseProvider";

export const browseContext = createContext();

const BrowseProvider = ({ children }) => {
    const [navigationPath, setNavigationPath] = useState("");
    const [navigationIdStack, setNavigationIdStack] = useState([])
    const [currentFolder, setCurrentFolder] = useState({})
    const [selected, setSelected] = useState()
    const [loading, setLoading] = useState(false)
    const { cloudService } = useContext(baseContext)
    const isRoot = navigationPath == "" || navigationPath == cloudService.root
    const breadcrumbPrefix = cloudService.isOneDrive ? "" : `${cloudService.name} > `
    const breadcrumb = isRoot ? "" : breadcrumbPrefix + navigationPath.substring(1).split("/").join(" > ")
    const currentFolderName = isRoot ? cloudService.name : navigationPath.split("/")[navigationPath.split("/").length - 1]
    const browseBackPath = navigationPath.substring(0, navigationPath.lastIndexOf("/"))
    const browseBackId = navigationIdStack.slice(0, -1).length == 0? '/' : navigationIdStack.slice(0, -1).pop()
    const unselectable = isRoot && (cloudService.isSharepoint || cloudService.isDocushare)
    const breadcrumbRef = useRef()
    const browseRoot = () => {
        setNavigationIdStack(["/"]) 
        return browseByPath(cloudService.root)
    } 
    const back = () => cloudService.isDocushare ? browseById({ id: browseBackId }, true) : browseByPath(browseBackPath)
    const browse = folder => {
        const browsePathPrefix = navigationPath == "/" ? "" : navigationPath
        const browsePath = `${browsePathPrefix}/${folder.name}`
        return cloudService.isDocushare ? browseById(folder) : browseByPath(browsePath)
    }
    const initializeBrowseIdStack = (path, idStack) => {
        if (idStack.length == 1 && idStack[0] == "/") return browseRoot()
        setNavigationIdStack(idStack)
        return browseTo(cloudService, idStack[idStack.length -1]).then(r => updateFolder(r, path))
    }
    const browseById = (folder, isBack) => {
        setLoading(true)
        isBack ? setNavigationIdStack(ns => ns.slice(0, -1)) : setNavigationIdStack(ns => [...ns, folder.id])
        const browsePath = isBack ? browseBackPath : `${navigationPath == "/" ? "" : navigationPath}/${folder.name}`   
        return browseTo(cloudService, folder.id).then(r => updateFolder(r, browsePath))
    }

    const browseByPath = path => {
        setLoading(true)
        const fixedPath = fixPath(cloudService, path)
        return browseTo(cloudService, fixedPath).then(r => updateFolder(r, fixedPath))
    }
    const updateFolder = (folder, path) => {
        setLoading(false)
        setCurrentFolder(folder)
        setNavigationPath(path)
    }
    const search = term => {
        setLoading(true)
        return getFolder(cloudService.cloudId, navigationPath, term).then(r => {
            setLoading(false)
            return r
        })
    }
    const addNewFolder = folderName => {
        setLoading(true)
        addFolder(cloudService.cloudId, `${navigationPath}/${folderName}`)
            .then(() => newFolderAdded())
            .catch(() => setLoading(false))
    }
    const newFolderAdded = () => {
        setLoading(false)
        browseByPath(navigationPath)
    }

    const contextElements = {
        navigationPath,
        navigationIdStack,
        currentFolder,
        isRoot,
        breadcrumb,
        loading,
        setLoading,
        currentFolderName,
        unselectable,
        browseRoot,
        back,
        browse,
        browseByPath,
        initializeBrowseIdStack,
        search,
        selected,
        setSelected,
        addNewFolder,
        breadcrumbRef
    }

    return (
        <browseContext.Provider value={contextElements}>
            {children}
        </browseContext.Provider>
    );
};

export default BrowseProvider