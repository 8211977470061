/* --------------------------------------------------------------------------------------
   xwButton.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { useRef, useEffect, useState, useContext } from "preact/hooks"
import { baseContext } from "../../providers/baseProvider"
import { MFDApp } from "../../constants/MFDApp.const"

const XwButton = props => {
    const elementRef = useRef(null) 
    const { buttonWasClicked } = useContext(baseContext)
    const [initialized, setInitialized] = useState(false)
    const clicked = () =>{
        buttonWasClicked()
        if(props.disabled){
            if(props.onDisabledClick){
                props.onDisabledClick();
            }
        }
        else if(props.onEnabledClick){
                props.onEnabledClick();
            }
    }
    useEffect(() => {
        $(elementRef.current).xrxbutton(getOptions(props))
        return () => safeDestroy(elementRef)
    }, [])

    useEffect(() => {
        if (initialized) {
            $(elementRef.current).xrxbutton('option','disabled', props.disabled)
        } else {
            setInitialized(true)
        }
    }, [props.disabled])

    useEffect(() => $(elementRef.current).xrxbutton('option', 'label', props.label), [props.label])

    return <>
    <span class={"xw-span-button"+ (props.disabled == true ? " disabled" : "")} onClick={clicked}>
    <button ref={elementRef} id={props.id} className={props.className} />
    </span>
    </>
}

const getOptions = function(props) {
    return {
    ...props,
    disabled: false,
    theme: props.theme || MFDApp.theme,
    text: !!props.label,
    icons: !props.glyph || { glyph: `xglyph-${props.glyph}` },
    width: props.widthScale && getWidth(props.widthScale, props.popup) || props.width
}
};

const is5inches = screen.width < 801
const widthScaleValues = {
    1: is5inches ? 760 : 944,
    2: is5inches ? 373 : 462,
    3: is5inches ? 246 : 304,
    4: is5inches ? 185 : 228,
    5: is5inches ? 139 : 171,
    6: is5inches ? 105 : 128
}
//TODO: Only the 5 inch "1" value is valid, the rest are placeholders
const popupWidthScaleValues = {
    1: is5inches ? 790 : 1008,
    2: is5inches ? 375 : 464,
    3: is5inches ? 246 : 304,
    
}

const getWidth = (widthScale, popup) => popup ? popupWidthScaleValues[widthScale] : widthScaleValues[widthScale];

const safeDestroy = ref => {
    try {
        $(ref.current).xrxbutton("destroy")
    } catch (e) { }
}

export default XwButton