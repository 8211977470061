/* --------------------------------------------------------------------------------------
   index.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Localizer, Text } from "preact-i18n"
import { XwActionBar, XwBoundingBox, XwButton } from "../../xerox/widgets"
import BaseModal from "../baseModal"
import Spinner from "../spinner"
import {decode} from "he";
import { is5inches } from "../../modules/util";
import { memo } from "preact/compat"; 


const PrivacyStatementPopup = ({onClose, display, loading, statement}) => {
    const actionBarTitle = <Text id="PRIVACY.TITLE" />
    const spinnerText = <Text id="COMMON.LOADING" />
    const cancel = () => onClose()
    return <BaseModal display={display}>
        <Localizer>
            <XwActionBar label={actionBarTitle} id="privacyStatementActionBar" buttonRight="#closePrivacyButton"
                themePrimary={false}>
                <div>
                    <XwButton id="closePrivacyButton" glyph="close" onEnabledClick={cancel} />
                </div>
            </XwActionBar>
        </Localizer>
        <div class="privacy-boundingbox-container">
        {loading ?
          <Localizer><Spinner spinnerText={spinnerText} /></Localizer>
          :
          <XwBoundingBox width={is5inches ? 786 : 1004} height={is5inches ? 375 : 502}
                boundingScroll={true}>
                <pre className="privacy-content-container">
                 {statement ? decode(statement) : null}
                </pre>
            </XwBoundingBox>  
        }
        </div>
    </BaseModal>
}
export default memo(PrivacyStatementPopup)