export const cloudServices = {
    googledrive: {
        keys: ["googledrive", "google drive"],
        name: "Google Drive",
        issuer: "Google",
        glyph: "xglyph-google-drive"
    },
    onedrive: {
        keys: ["onedrive"],
        name: "OneDrive",
        issuer: "Microsoft",
        glyph: "xglyph-onedrive",
        root: "/OneDrive"
    },
    docushare: {
        keys: ["docushare go", "docushare go us", "docushare_us"],
        name: 'DocuShare Go',
        issuer: "Xerox",
        glyph: 'xglyph-docushare-logo'
    },
    docushare_eu: {
        keys: ["docushare_eu", "docushare go eu"],
        name: 'DocuShare Go',
        issuer: "Xerox",
        glyph: 'xglyph-docushare-logo'
    },
    sharepoint: {
        keys: ["sharepoint", "ms365", "sharepoint/ms365", "ms365/sharepoint"],
        name: 'SharePoint',
        issuer: "Microsoft",
        glyph: 'xglyph-folder'
    }
}
