/* --------------------------------------------------------------------------------------
   jobSettingsProvider.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { createContext } from "preact";
import { useState } from "preact/hooks";
import { Text } from "preact-i18n";

export const validationContext = createContext()
const forbiddenChars = ["\\", "/", ":", "*", "?", "\"", "<", ">", "|", ";", "`"]
const invalidCharsBannerMessage = { first: <Text id={"ERROR.CHARACTERS_CANNOT_BE_USED"} /> }

const ValidationProvider = props => {
    const [validationBannerContent, setValidationBannerContent] = useState();
    const hasInvalidChars = (input) => forbiddenChars.some(c => input && input.includes(c))

    const onInvalidChars = (resetInputCallback) => {
        setValidationBannerContent(invalidCharsBannerMessage)
        resetInputCallback()
    }

    const validateFilenameInput = (input, resetInputCallback) => {
        const isInvalid = hasInvalidChars(input);
        const emptyOrUnchanged = !input.trim().length
        if(isInvalid){
            onInvalidChars(resetInputCallback)
        }
        if (!validationBannerContent && emptyOrUnchanged) {
            resetInputCallback()
        }
        return !isInvalid;
    }

    const sanitizeInput = (input) =>{
       return [...input].filter(char => !forbiddenChars.includes(char)).join("");
    }

    const contextElements = {
        validationBannerContent,
        setValidationBannerContent,
        hasInvalidChars,
        validateFilenameInput,
        sanitizeInput
    }

    return <validationContext.Provider value={contextElements}>
        {props.children}
    </validationContext.Provider>
}

export default ValidationProvider