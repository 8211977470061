/* --------------------------------------------------------------------------------------
   browse.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { getFolder, getSharepointLibs } from "./browseClient"

const browseSharepoint = path => path == "/" ? getSharepointLibs().then(r => sharepointResult(r)).catch(e=>Promise.reject(e)) : getFolder("sharepoint", path)

const sharepointResult = r => ({ children: r.map(result => ({ name: result.replace("/",""), is_folder: true })) })

export const fixPath = (cloud, path) => path == "" || path == "/"?  cloud.root : path 

export const browseTo = (cloud, path) => cloud.isSharepoint? browseSharepoint(path) : getFolder(cloud.cloudId, path)

export const scanDestinationComponent = "destination"