/* --------------------------------------------------------------------------------------
   baseProvider.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { createContext } from 'preact';
import { IntlProvider } from 'preact-i18n';
import { useEffect, useState } from 'preact/hooks';
import enIntlDef from '../assets/i18n/en.json';
import { getAppGallerySettings } from '../modules/appGallery';
import { getAuthToken } from '../modules/oauthClient';
import { getCloudService } from '../modules/cloud';
import { sessionVar } from '../constants/storage.const';
import { setSessionVar } from '../modules/browserStorage';

export const baseContext = createContext()

const BaseProvider = ({ children }) => {
    const [activity, setActivity] = useState()
    const [bannerContent, setBannerContent] = useState()
    const [showFileTypeModal, setShowFiletypeModal] = useState(false)
    const [deviceInformation, setDeviceInformation] = useState()
    const [buttonClick, setButtonClick] = useState(0)
    const [appGallerySettings] = useState(getAppGallerySettings())
    const { cloud, serial, spSite, community, key } = appGallerySettings || {}
    const [middlewareToken, setMiddlewareToken] = useState()
    const [ cloudService ] = useState(getCloudService(cloud))    
    const buttonWasClicked = () => setButtonClick(buttonClick + 1)
    //TODO: Refactor this language detection code and move it somewhere else
    let intlDefinition = enIntlDef
    let supportedLanguages = require('../assets/i18n/supported-languages.json');
    const deviceLanguage = window.navigator.language.split('-')[0];
    if (supportedLanguages.includes(deviceLanguage)) {
        intlDefinition = require(`../assets/i18n/${deviceLanguage}.json`);
    }
    const sessionVariables = [
        { key: sessionVar.communityString, value: community},
        { key: sessionVar.sharepointSite, value: spSite},
        { key: sessionVar.apiKey, value: key }
    ]
    useEffect(() => sessionVariables.map(s => setSessionVar(s.key, s.value)), [])
    useEffect(() => getAuthToken(serial, key).then(r => setMiddlewareToken(r)).catch(() => setMiddlewareToken(0)), [])
    useEffect(() => setSessionVar(sessionVar.xsaToken, middlewareToken), [middlewareToken])

    const contextElements = {
        activity, setActivity,
        bannerContent, setBannerContent, showFileTypeModal, setShowFiletypeModal,
        deviceInformation, setDeviceInformation, buttonClick, buttonWasClicked,
        deviceLanguage, appGallerySettings, middlewareToken, cloudService
    }

    return <baseContext.Provider value={contextElements}>
        <IntlProvider definition={intlDefinition}>
            {children}
        </IntlProvider>
    </baseContext.Provider>
}

export default BaseProvider