/* --------------------------------------------------------------------------------------
   errorTypes.const.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

// NOTE: FM_001 is built and returned by serviceStatusCheck.js

export const errorTypes = {
  ecommerceError: {
    code: "FM_002",
    name: "Xerox eCommerce issue",
    title: "ERROR.APP_UNAVAILABLE_AT_THIS_TIME",
    textKeys: [
      "ERROR.TRY_AGAIN",
      "ERROR.CONTACT_XEROX_SUPPORT"
    ]
  },
  appGalleryError: {
    code: "FM_003",
    name : "App Gallery Configuration issue",
    title: "ERROR.APP_CONFIGURATION_ERROR",
    textKeys: [
      "ERROR.CHECK_APP_CONFIGURATION_SETTINGS"
    ]
  },
  fileNameAlreadyExists: {
    code: "FM_004",
    name: "File already exists",
    title: "ERROR.FILENAME_ALREADY_EXISTS",
    textKeys: ["ERROR.ENTER_DIFFERENT_FILENAME"],
  },
  genericError: {
    code: "FM_005",
    name: "Generic Error",
    title: "ERROR.AN_ERROR_HAS_OCCURED",
    textKeys: ["ERROR.PLEASE_TRY_AGAIN", "ERROR.IF_PROBLEM_CONTACT_XEROX"],
  },
  alumaTokenError: {
    code: "FM_006",
    name : "Invalid Aluma token",
    title: "ERROR.ALUMA_TOKEN_ERROR",
    textKeys: [
      "ERROR.RESTART_APP_AND_TRY_AGAIN"
    ]
  },
  networkError: {
    code: "FM_007",
    status: 0,
    name : "App cannot connect to the network.",
    title: "ERROR.THE_APP_CANNOT_CONNECT_TO_NETWORK",
    textKeys: [
      "ERROR.CHECK_YOUR_DEVICE_NETWORK_CONNECTION"
    ]
  },
  scanError: {
    code: "FM_009",
    name: "A scan error has occured.",
    title: "ERROR.SCAN_ERROR",
    textKeys: ["ERROR.PLEASE_TRY_AGAIN", "ERROR.IF_PROBLEM_CONTACT_XEROX"],
  },
  browseError: {
    code: "FM_010",
    name: "Browse",
    status: 404,
    title: "ERROR.BROWSING_ERROR",
    textKeys: ["ERROR.RESTART_APP_AND_TRY_AGAIN"],
  },
  maintenanceModeError: {
    code: "FM_011",
    name: "Installing App Updates",
    title: "ERROR.INSTALLING_APP_UPDATES",
    textKeys: ["ERROR.THIS_MAY_TAKE_SEVERAL_MINUTES", "ERROR.CLOSE_ALERT_CONTINUE_WORKING"]
  },
  badRequestError: {
    code: "FM_012",
    name: "Generic Error",
    title: "ERROR.AN_ERROR_HAS_OCCURED",
    status:400,
    textKeys: ["ERROR.PLEASE_TRY_AGAIN", "ERROR.IF_PROBLEM_CONTACT_XEROX"],
  },
};