/* --------------------------------------------------------------------------------------
   scanSettingsOptions.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { MFDApp } from "../constants/MFDApp.const"
import { scanSettingsOptions } from "../constants/scanSettings.const"
import { localVar } from "../constants/storage.const"
import { clearLocalVar, getLocalVar, setLocalVar } from "./browserStorage"

const storedUserSettings = () => JSON.parse(getLocalVar(localVar.userScanSettings))

const initialScanSettingsListReducer = (obj, item) => ({ ...obj, [item]: getSelectedOption(item) })

const storedOption = item => scanSettingsOptions[item].find(i => i.key == storedUserSettings()[item])

const getSelectedOption = item => storedUserSettings() && storedOption(item) ? storedOption(item) : getDefaultOption(item)

const getDefaultOption = item => getCustomDefault(item) ?
    scanSettingsOptions[item].find(i => i.key == getCustomDefault(item)) :
    scanSettingsOptions[item][0]

const getCustomDefault = item => MFDApp.scanSettings.customDefaults[item];

const filterScanSettings = (altalink, a3) => ({
    ...scanSettingsOptions,
    Resolution: altalink ? scanSettingsOptions.Resolution : scanSettingsOptions.Resolution.filter(s => !s.altalink),
    OriginalSize: a3 ? scanSettingsOptions.OriginalSize : scanSettingsOptions.OriginalSize.filter(s => !s.a3),
    OriginalType: altalink ? scanSettingsOptions.OriginalType : scanSettingsOptions.OriginalType.filter(s => !s.altalink),  
})

const isAltaLink = model => model.toLowerCase().includes("alta")
const isA3 = model => model.replace(/[^0-9]/g, "").length == 4

export const getInitialScanSettings = settingList => settingList.reduce(initialScanSettingsListReducer, {})

export const getScanSettingsForModel = model => filterScanSettings(isAltaLink(model), isA3(model))

export const storeScanSettings = userScanSettings => setLocalVar(localVar.userScanSettings, JSON.stringify(userScanSettings))

export const clearUserScanSettings = () => clearLocalVar(localVar.userScanSettings)